import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { MetaTags } from 'react-meta-tags';
import ConnectionAPI from '../../../api';
import CarsListVertical from '../../../sections/CarsList/Vertical';
import { withTranslation } from 'react-i18next';

const pageSize = 10;

class OrdersPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            orders : [],
            cars: [],
            loading: false,
            currentPage: 1,
            totalPages: 0
        }
    }

    componentDidMount = () => {
        this.getOrders();
    }
    
    getOrders = async ({page}={page : 1}) => {
        this.setState({ loading: true });
        const { user } = this.props;
        const response = await ConnectionAPI.getOrders();
        if(response.status === 200){
            const { orders } = response.message;
            const totalPages = Math.ceil(orders.length / pageSize);
            const paginatedOrders = orders.slice((page - 1) * pageSize, page * pageSize);
            const cars = orders.map(order => ({...order.car, ...order}));
            this.setState({ cars, orders: paginatedOrders, loading: false, currentPage: page, totalPages, total: orders.length });
        } else {
            this.setState({ loading: false });
        }
    }

    onChangePage = (event, page) => {
        this.getOrders({ page });
    }
    
    render = () => {
        const { user, t } = this.props;
        const { orders, total, cars, loading, currentPage, totalPages } = this.state;
        if(!user.id){return null};
        return (
            <div className='page'>
                <MetaTags>
                    <title>My Orders</title>
                    <meta name="description" content="Manage your Account" />
                    <meta property="og:title" content="Account" />
                </MetaTags>
                <div className='container'>
                    <CarsListVertical
                        showCantFindCard={false}
                        title={t('order_1')}
                        total={total}
                        cars={cars}
                        isOrder={true}
                        loading={loading}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onChangePage={this.onChangePage}
                    />
                </div>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }
  
export default connect(mapStateToProps)(withTranslation()(OrdersPage));