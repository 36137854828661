import { useTranslation } from 'react-i18next';
import { loadingIcon } from '../../../assets/images';
import { isMobile } from '../../../utils/utils';
import './index.scss';

const LoadingBar = (props) => {
    const { currentCount = 0} = props;
    const mobile = isMobile(window);
    const { t, i18n } = useTranslation();
    return (
        <div className="loading-bar-container">
            <div className='flex items-center'>
                <h2 className='mr-5'>{currentCount}%</h2>
                <h5>{t('find_your_car_9')}</h5>
            </div>
            <div className='w-full stack-top !justify-items-start'>
                <div className='loading-bar-background'></div>
                <div className='loading-bar-current-background'
                style={{
                    width: `${currentCount}%`
                }}></div>
            </div>   
        </div>
    )  
}

export default LoadingBar;