/* Create Hero Section with Image and Text */
import React from 'react';
import './index.scss';
import { CheckCircle, EnhancedEncryptionOutlined, FavoriteBorderOutlined, SupportOutlined } from '@mui/icons-material';
import HowItWorksContainer from '../HowItWorksContainer';
import { useTranslation } from 'react-i18next';
import InformationCard from '../../components/InformationCard';
import { isMobile } from '../../../utils/utils';
import { versicarHowItWorks1, versicarHowItWorks2, versicarHowItWorks3, versicarHowItWorks4 } from '../../../assets/images';


const faq = [
    {
        question : 'Choose your car',
        answer : 'Choose your car from our wide selection of cars.'
    },
    {
        question : 'Transparent pricing',
        answer : 'We offer transparent pricing.'
    },
    {
        question : 'Hassle free delivery',
        answer : 'We deliver your car to your doorstep.'

    }   
]


const WorksHero = () => {
    const { t, i18n } = useTranslation();
    const info = [
        {
            title: t('work_1'),
            desc : t('work_2')
        },
        {
            title: t('work_3'),
            desc : t('work_4')
        },
    ];

    const mobile = isMobile(window);
    return (
        <div>            
            <div className='section background box-background'>
                <h2 className='center small-container'> {t('work_23')} </h2>
                <div className='container'>
                    <div className='grid-75'>
                        <div className=''>
                            <h1 className='primary-text'> 01.</h1>
                        </div>
                        <div className=''>
                            {/* <h4 className='grey'>{item[0]}</h4> */}
                            <h2> {t('work_1')} </h2>
                            <div className='small-container'></div>
                            <p> {t('work_2')} </p>
                        </div>
                    </div>
                    <div>
                        <div className='grid-75'>
                            <div className=''>
                                <h1 className='primary-text'> 02.</h1>
                            </div>
                            <div className=''>
                                <h2> {t('work_3')} </h2>
                                <div className='small-container'></div>
                                <p> {t('work_4')} </p>
                            </div>
                        </div>
                        <div className={mobile ? '' : 'grid-33'}>
                                <InformationCard title={t('work_6')} content={t('work_7')}/>
                                <InformationCard title={t('work_8')} content={t('work_9')}/>
                                <InformationCard title={t('work_10')} content={t('work_11')}/>
                        </div>
                    </div>
                    <div className='grid-75'>
                        <div className=''>
                            <h1 className='primary-text'> 03.</h1>
                        </div>
                        <div className=''>
                            {/* <h4 className='grey'>{item[0]}</h4> */}
                            <h2> {t('work_12')} </h2>
                            <div className='small-container'></div>
                            <p> {t('work_13')} </p>
                        </div>
                    </div>
                    <div>
                        <div className='grid-75'>
                            <div className=''>
                                <h1 className='primary-text'> 04.</h1>
                            </div>
                            <div className=''>
                                <h2> {t('work_14')} </h2>
                                <div className='small-container'></div>
                                <p> {t('work_15')} </p>
                            </div>
                        </div>
                        <h3> {t('work_16')} </h3>
                        <div className='grid-50'>
                                <InformationCard 
                                    maxHeight={450}
                                    title={t('work_17_1')} 
                                    smallTitle={true}
                                    subTitle={
                                        <div className='block'>
                                            <h3>
                                                {t('work_17_2')} 
                                            </h3>
                                            <h5>
                                                {t('work_17_3')} 
                                            </h5>
                                        </div>
                                    }
                                    content={<img src={versicarHowItWorks1}/>}
                                />
                                <InformationCard 
                                    maxHeight={450}
                                    title={t('work_18_1')} 
                                    smallTitle={true}
                                    subTitle={
                                        <div className='block'>
                                            <h3>
                                                {t('work_18_2')} 
                                            </h3>
                                            <h5>
                                                {t('work_18_3')} 
                                            </h5>
                                        </div>
                                    }
                                    content={<img src={versicarHowItWorks2}/>}
                                />
                        </div>
                        <div className='grid-50'>
                            <InformationCard 
                                    maxHeight={450}
                                    title={t('work_19_1')} 
                                    smallTitle={true}
                                    subTitle={
                                        <div className='block'>
                                            <h3>
                                                {t('work_19_2')} 
                                            </h3>
                                            <h5>
                                                {t('work_19_3')} 
                                            </h5>
                                        </div>
                                    }
                                    content={<img src={versicarHowItWorks3}/>}
                                />
                                <InformationCard 
                                    maxHeight={450}
                                    title={t('work_20_1')} 
                                    smallTitle={true}
                                    subTitle={
                                        <div className='block'>
                                            <h3>
                                                {t('work_20_2')} 
                                            </h3>
                                            <h5>
                                                {t('work_20_3')} 
                                            </h5>
                                        </div>
                                    }
                                    content={<img src={versicarHowItWorks4}/>}
                                />
                        </div>
                        <h3> {t('work_21')} </h3>
                        <h6> {t('work_22')} </h6>
                    </div>
                </div>   
            </div>
            <HowItWorksContainer hideKnowMore={true}/>
        </div>

    )
}   

export default WorksHero;