/* Create Hero Section with Image and Text */
import './index.scss';
import React, {useState } from 'react';
import { useTranslation } from 'react-i18next';
import CarRequestForm from '../../components/CarRequestForm';
import CarFindForm from '../../components/CarFindForm';
import SelectCard from '../../components/Card/Select';
import { carIcon, linkIcon } from '../../../assets/images';
import ContainedLoading from '../../components/ContainedLoading';
import { isMobile } from '../../../utils/utils';
import LoadingBar from '../../components/LoadingBar';

const FindYourCarHero = () => {
    const { t, i18n } = useTranslation();
    const [useForm, setUseForm] = useState(true);
    const [loading, setLoadingState] = useState(false);
    const [loadingCount, setLoadingCountState] = useState(0);
    const intervalRef = React.createRef();

    const changeDisplay = (isForm) => {
        if(useForm != isForm){
            setUseForm(isForm)
        }
    }

    const setLoading = async (value,error=false) => {
        const lastValue = loading;

        if(lastValue && !value){ //was loading but now it has ended
            clearInterval(intervalRef.current);
            if(error)
                setLoadingCountState(0);
            else{
                setLoadingCountState(100);
                await setTimeout(function (){}, 1000); 
            }
        }
        setLoadingState(value);

        //fake loading
        if(!lastValue){
            intervalRef.current = setInterval(() => {
                setLoadingCountState((prevCount) => {
                  if (prevCount >= 90) {
                    clearInterval(intervalRef.current); // Stop timer after 10 seconds
                    return prevCount;
                  }
                  return prevCount + 10; // Increment count every 100ms
                });
              }, 2000);
        }
    }
    

    const mobile = isMobile(window);
    return (
        <div className='find-your-car-container section center'>
            <div className='content'>
            {
    loading ? (
        <div>
            <LoadingBar currentCount={loadingCount}/>
            <br/>
            <h6>{t('find_your_car_10')}</h6>
        </div>
    ) : (
        <>
            <div className={`${mobile ? '' : 'grid-50 w-full mb-5'}`}>
                <SelectCard
                    icon={carIcon}
                    text={t('find_your_car_3')}
                    isSelected={useForm}
                    onClick={() => changeDisplay(true)}
                />
                <SelectCard
                    icon={linkIcon}
                    text={t('find_your_car_4')}
                    isSelected={!useForm}
                    onClick={() => changeDisplay(false)}
                />
                </div>
                {useForm ? ( 
                    <CarRequestForm />
                ) : (
                    <CarFindForm setLoading={setLoading} />
                )}
                </>
                )
                }
            </div>
        </div>
    );
};

export default FindYourCarHero;
