/* Create Horizontal Card Car Component */
import React, { useEffect, useState } from 'react';
import './index.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { selectedIcon, selectedIconFalse } from '../../../../assets/images/index.js';
import { isMobile } from '../../../../utils/utils.js';

const SelectCard = (props) => {
    const {icon,text,isSelected, onClick} = props;

    const mobile = isMobile(window);
    return (
        <div className={`select-card-container hover:cursor-pointer 
            ${isSelected ? 'selected-card' : ''}
            ${mobile ? 'mb-5' : ''}`} onClick={() => onClick()}>
            <div className={`flex flex-row-reverse pt-5 pr-5`}>
                {   
                    isSelected ?
                    <img src={selectedIcon}/>
                    :
                    <img src={selectedIconFalse}/>
                }
            </div>
            <div className={`flex flex-col !items-start pl-5 pb-5`}>
                <img src={icon} className='mb-2'/>
                {
                    mobile ?
                    <h4 className='text-left'>{text}</h4>
                    :
                    <h2>{text}</h2>
                }
                
           </div>
        </div>
    )
}

export default SelectCard;